import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Thankyou from "../pages/Thankyou";
import PurchaseContainer from "../pages/checkout/PurchaseContainer";
import history from "./history";
import { AppRoute } from "../models/route";
import clsx from "clsx";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { selectCountryLoading } from "../store/selectors/storeSelector";
import { getCountryById } from "../store/actions/countryActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles(
  (theme: Theme) => ({
    background: {
      background: `linear-gradient(0deg, rgba(30,53,94,1) 0%, rgba(63,130,165,1) 100%)`,
      minHeight: "100vh",
    },
  }),
  { name: "layout" }
);

const AppRouter: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector(selectCountryLoading);
  useEffect(() => {
    dispatch(getCountryById({ id: "US" }));
  }, [dispatch]);

  return (
    <Router history={history}>
      <div className={clsx([classes.background])}>
        <Switch>
          <Route exact path={AppRoute.Home} component={Home} />
          <Route exact path={AppRoute.Checkout} component={PurchaseContainer} />
          <Route exact path={AppRoute.Thankyou} component={Thankyou} />

          <Redirect exact path="/" to={AppRoute.Home} />
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;
