import { combineEpics } from "redux-observable";
import { Action } from "ts-action";
import { RootState } from "../reducers";
import { autoUpdateServiceWorker$ } from "./serviceWorkerEpic";
import cartEpic$ from "./cartEpics";
import financialEpic$ from "./financialEpics";
import { getCountry$ } from "./countryEpics";

export const rootEpic = combineEpics<Action, Action, RootState>(
  autoUpdateServiceWorker$,
  cartEpic$,
  getCountry$,
  financialEpic$
);
