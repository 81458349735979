import React, { PropsWithChildren } from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { WizardInput, TextInputButton } from "./WizardInput";

const WizardInputButtonGroup = withStyles(
  (theme: Theme) => ({
    root: {
      width: `calc(100% + ${theme.spacing(1)})`,
      margin: theme.spacing(-0.5),
      display: "grid",

      gridGap: "35px 20px",

      [theme.breakpoints.up("lg")]: {
        gridTemplateColumns: "1fr 1fr 1fr",
      },
      [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "1fr 1fr",
      },
    },
  }),
  { name: "common" }
)(ToggleButtonGroup);

export interface Options {
  id: string;
  value: string;
  label: string;
  type: string;
}

interface Props {
  id: string;
  options: Options[];
  onChange: (value: object) => void;
  value?: Options;
  type?: string;
  className?: string;
}

export default function WizardInputs(props: PropsWithChildren<Props>) {
  const { options, onChange, ...other } = props;

  return (
    <WizardInputButtonGroup
      exclusive
      onChange={(e, v) => onChange(v)}
      {...other}
    >
      {options.map((option) => {
        if (option.type === "static") {
          return (
            <WizardInput key={option.id} id={option.id} value={option}>
              {option.label}
            </WizardInput>
          );
        }

        if (option.type === "editable") {
          return (
            <TextInputButton
              key={option.id}
              id={option.id}
              options={option}
              label={option.label}
              onTextChange={(id, value) => {
                onChange(value);
              }}
            />
          );
        }
        return <></>;
      })}
    </WizardInputButtonGroup>
  );
}
