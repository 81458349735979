import { withStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import React, { useRef, useState } from "react";
import { Options } from "./WizardInputs";
import clsx from "clsx";

export const WizardInput = withStyles(
  (theme: Theme) => ({
    root: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: "2px solid #59CAFA !important",
      minWidth: theme.spacing(10),
      borderRadius: "5px !important",
      fontSize: theme.spacing(1.5),
      fontWeight: "bold",
      height: theme.spacing(4),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      "&$selected": {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        "&:hover": {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        },
      },
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    /* Pseudo-class applied to the root element if `selected={true}`. */
    selected: {},
  }),
  { name: "common" }
)(ToggleButton);

interface TextInputProps {
  key: string;
  id: string;
  options?: Options;
  className?: string;
  label?: string;
  onTextChange: (id: string, value: object) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 5,
    color: "#3f82a5",
    backgroundColor: theme.palette.common.white,
    border: `2px solid #59CAFA`,
    minWidth: theme.spacing(10),
    justifyContent: "center",
    fontSize: theme.spacing(1.5),
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    height: theme.spacing(4),
    gridColumn: "2 / span 2",
    [theme.breakpoints.down("lg")]: {
      gridColumn: "1 / span 2",
    },
    "& .MuiButton-root": {
      fontSize: theme.spacing(1.5),
      color: theme.palette.common.white,
      backgroundColor: "#59CAFA",
      height: theme.spacing(4),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiInput-input": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
      height: theme.spacing(4),
      fontSize: theme.spacing(1.5),
      color: '#59CAFA',
    },
  },
  none: {},
  active: {},
  hover: {},
}));

enum InputState {
  NONE = 1,
  ACTIVE,
  HOVER,
}

export function TextInputButton(props: TextInputProps) {
  const inputRef = useRef();
  const [inputState, setInputState] = useState(InputState.NONE);
  const classes = useStyles();

  return (
    <TextField
      className={clsx(
        {
          [classes.none]: inputState === InputState.NONE,
          [classes.active]: inputState === InputState.ACTIVE,
          [classes.hover]: inputState === InputState.HOVER,
        },
        classes.root
      )}
      inputRef={inputRef}
      id={props.id}
      type="number"
      placeholder="ENTER OTHER AMOUNT.."
      value={props.options?.value}
      onBlur={() => {
        setInputState(InputState.NONE);
      }}
      onChange={(event) => {
        let options = props.options as Options;
        if (options) {
          options.value = event.target.value;
        }
        props.onTextChange(props.id, options);
      }}
      onFocus={() => {
        const options = props.options as Options;
        props.onTextChange(props.id, options);
        setInputState(InputState.ACTIVE);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Button
              onClick={() => {
                if (inputRef && inputRef.current) {
                  // @ts-ignore
                  inputRef.current.focus();
                  const options = props.options as Options;
                  props.onTextChange(props.id, options);
                  setInputState(InputState.ACTIVE);
                }
              }}
            >
              $
            </Button>
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
}
