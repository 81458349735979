import React from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ContentContainer from "components/ContentContainer";
import Logo from "./LogoFooter";
import { ExternalLink } from "./Link";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      "& .MuiLink-root": {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(1.6),
        paddingLeft: 0,
      },
      "& .MuiTypography-root": {
        paddingRight: theme.spacing(1),
      }
    },
    credit: {
      paddingTop: theme.spacing(0.5),
    },
    textLink: {
      textAlign: "center",
    },
    logo: {
      [theme.breakpoints.up("xs")]: {
        width: "10%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "30%",
      },
    }
  }),
  { name: "footer" }
);

function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ContentContainer
      disableGrid
      background={"footer"}
      className={classes.root}
    >
      <ContentContainer size={"footer"}>
        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems={"center"} justify={"center"}>
            <Logo id={"footer-logo"} className={clsx(classes.logo)} />
          </Grid>
          <Grid container item xs={12} alignItems={"center"} justify={"center"}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              justify={isMobile ? "center" : "flex-end"}
            >
              <Typography
                id={"info-copyright-1"}
                color={"textSecondary"}
                align={"center"}
              >
                &copy; 2021 Motili, Inc.
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={6}
              justify={isMobile ? "center" : "flex-start"}
            >
              <ExternalLink
                id={"info-email"}
                variant={"subtitle2"}
                color={"primary"}
                href={"mailto:info@motilicares.com"}
                label={"info@motilicares.com"}
              />
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </ContentContainer>
  );
}

export default Footer;
