import { on, reducer } from "ts-action";
import { DonationCart as Cart } from "../../models/cart";

import {
  setLoading,
  updateCartAmount,
  updateCartSuccess,
  updateCartError,
  placeOrder,
  placeOrderSuccess,
  placeOrderError,
  resetCart,
  cartProcess,
} from "../actions/cartActions";
import { AppRoute } from "../../models/route";

const initialState: Cart = {};
export const cartReducer = reducer(
  initialState,
  on(setLoading, (state, action) => ({
    ...state,
    ...action.payload.cart,
    loading: action.payload.loading,
  })),
  on(updateCartAmount, (state, action) => ({
    ...state,
    ...action.payload.cart,
    loading: false,
    error: null,
  })),
  on(updateCartSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
    error: null,
  })),
  on(updateCartError, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  })),
  on(placeOrder, (state, action) => ({
    ...state,
    ...action.payload.cart,
    paymentMethod: action.payload.paymentMethod,
    loading: true,
    error: null,
    nextRoute: AppRoute.Thankyou,
  })),
  on(placeOrderSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
    error: null,
    nextRoute: AppRoute.Thankyou,
  })),
  on(placeOrderError, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  })),
  on(resetCart, () => ({
    ...initialState,
  })),
  on(cartProcess, (state, action) => ({
    ...state,
    loading: action.payload.loading,
    error: action.payload.error,
  }))
);

export default cartReducer;
