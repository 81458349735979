import { ThemeOptions } from "@material-ui/core/styles";

// A theme for Daikin Pure Air
export const theme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 920,
      xl: 1280,
    },
  },
  palette: {
    primary: {
      main: "#00CDFF", // variable; Button background
      light: "#FFFFFF", // variable; background1
      dark: "#000000", // variable; footerBackground
      contrastText: "#FFFFFF", // Button Text color for contained
    },
    secondary: {
      main: "#00415F", // variable; Button background
      light: "#FFFFFF", // variable; background3
      dark: "#F9F9F9", // variable; background2
      contrastText: "#FFFFFF", // Button Text color for contained
    },
    info: {
      main: "#2196f3",
      dark: "#0097E0", // variable; Button contained hover state
      light: "#FCF3F0", // variable; Wizard tip background color
    },
    text: {
      primary: "#000000", // Text
      secondary: "#FFFFFF", // Text
    },
    divider: "#CCCCCC", // also used in border color
    action: {
      active: "#0097E0", // Link active/hover state
      selected: "#666666", // variable; Wizard button selected
      hover: "#333333", // Outlined button border color in hover state
    },
  },
  typography: {
    h1: {
      fontFamily: "Nunito Sans", // variable
      fontWeight: 800, // variable
      fontSize: "52px",
      lineHeight: "55px",
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Nunito Sans", // variable
      fontWeight: 800, // variable
      fontSize: "36px",
      lineHeight: "50px",
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Nunito Sans", // variable
      fontWeight: 400, // variable
      fontSize: "24px",
      lineHeight: "34px",
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Nunito Sans", // variable
      fontWeight: 700, // variable
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "1px",
      textTransform: "uppercase",
      color: "#F14F27",
    },
    body1: {
      // Paragraph
      fontFamily: "Nunito Sans", // variable
      fontWeight: 400, // variable
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    body2: {
      // Paragraph Small
      fontFamily: "Nunito Sans", // variable
      fontWeight: 400, // variable
      fontSize: "11px",
      lineHeight: "15px",
      letterSpacing: 0,
    },
    subtitle1: {
      // Link
      fontFamily: "Nunito Sans", // variable
      fontWeight: 700, // variable
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    subtitle2: {
      // Footer
      fontFamily: "Nunito Sans", // variable
      fontWeight: 400, // variable
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: 0,
    },
    button: {
      // Button
      fontFamily: "Nunito Sans", // variable
      fontWeight: 600, // variable
      fontSize: "14px",
      lineHeight: "24px",
      textAlign: "center",
      textTransform: "uppercase",
    },
  },
  spacing: 10,
  shape: {
    borderRadius: 20,
  },
};

export default theme;
