import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "../components/Button";
import { AppRoute } from "../models/route";

import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import Footer from "../components/Footer";
import ContentContainer from "components/ContentContainer";
import WizardInputs, { Options } from "components/WizardInputs";
import { selectCart } from "../store/selectors/cart";
import { updateCartAmount } from "../store/actions/cartActions";
import { DonationCart as Cart } from "../models/cart";
import config from "../config";

import motiliCaresImage from "../assets/images/motilicares-02.png";
import robsim0 from "../assets/images/robsim0.png";
import robsim1 from "../assets/images/robsim1.png";
import creditCardsImage from "../assets/images/credit-cards.png";
import mainImage from "../assets/images/BPS126936-1.jpg";
import footerImage from "../assets/veterans/SouthernTierVeteransGroupLogo.png";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: theme.spacing(70),
    },
    rootContainer: {
      maxWidth: "1050px",
      margin: "auto",
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(7),
      borderRadius: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },
    main: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        borderRadius: theme.spacing(0),
      },
    },
    leftGroup: {
      paddingRight: theme.spacing(3),
      borderTopLeftRadius: theme.spacing(1),
      borderBottomLeftRadius: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        borderRadius: theme.spacing(0),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    rightGroup: {
      paddingLeft: theme.spacing(3),
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        borderRadius: theme.spacing(0),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    donateAmountOption: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    donateLabel: {},
    donateText: {
      fontWeight: "bold",
    },
    donateAmount: {
      margin: "20px 0px",
    },
    motiliLogo: {
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
    topImage: {
      textAlign: "center",
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    linkDonate: {
      "& a": {
        height: "40px !important",
        borderRadius: theme.spacing(0.4),
        backgroundColor: "#00CDFF !important",

        textDecoration: "none",
        fontWeight: "bold",
        textTransform: "uppercase",
        color: theme.palette.common.white,
        fontSize: theme.spacing(1.6),

        width: "100%",
        textAlign: "center",
        paddingTop: theme.spacing(1.3),
      },

      "& :hover": {
        borderColor: "white !important",
        backgroundColor: "#00CDFF !important",
      },
    },
    linkDonateDisabledMain: {},
    linkDonateDisabled: {
      height: "40px !important",
      borderRadius: theme.spacing(0.4),
      backgroundColor: "#00CDFF !important",

      textDecoration: "none",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: theme.palette.common.white,
      fontSize: theme.spacing(1.6),

      width: "100%",
      textAlign: "center",
      paddingTop: theme.spacing(1.3),

      opacity: 0.4,
    },
    creditCardsImage: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "layout" }
);

let _options: Options[] = [
  { id: "opt1", value: "50", label: "$50", type: "static" },
  { id: "opt2", value: "25", label: "$25", type: "static" },
  { id: "opt3", value: "10", label: "$10", type: "static" },
  { id: "opt4", value: "5", label: "$5", type: "static" },
  { id: "opt5", value: "", label: "", type: "editable" },
];

const _initDonateOptions: Options = {
  id: "temp",
  value: "0",
  label: "",
  type: "empty",
};

function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [donateAmount, setDonateAmount] = React.useState(_initDonateOptions);
  const [options, setOptions] = React.useState(_options);
  const [minimumDonate] = React.useState(config.minimumDonateValue || 0);

  const cart: Cart = useSelector(selectCart);

  const handleDonateAmount = (newAmount: object) => {
    const roundDown = (_number: string, decimals: number) => {
      decimals = decimals || 0;
      const number: number = +_number;
      return (
        Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      ).toString();
    };

    const decimalOnly2Digit = (_number: string) => {
      const numberArr = _number.split(".");
      let number = "";
      if (numberArr.length > 0) {
        number += numberArr[0];
      }
      if (numberArr.length > 1 && numberArr[1].length > 0) {
        number +=
          "." +
          numberArr[1].substring(
            0,
            numberArr[1].length > 2 ? 1 : numberArr[1].length
          );
      }
      return number;
    };

    if (newAmount) {
      const _newAmount = newAmount as Options;
      const roundNewValue = roundDown(_newAmount.value, 2);
      const newValue = decimalOnly2Digit(_newAmount.value);

      _newAmount.value =
        Number(_newAmount.value) === Number(roundNewValue)
          ? newValue
          : roundNewValue;
      setDonateAmount(_newAmount);

      const newValueAmount = _newAmount.value;
      dispatch(
        updateCartAmount({
          cart: {
            ...cart,
            amount: Number(newValueAmount),
          },
        })
      );
    }
  };

  useEffect(() => {
    if (donateAmount.type === "editable") {
      let editable = options.find((e) => e.type === donateAmount.type);
      if (editable) {
        editable.value = donateAmount.value;
        editable.label = donateAmount.value;
      }
    }
    setOptions(options.filter((e) => e.id !== "false"));
  }, [donateAmount]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ContentContainer disableGrid appContainer>
      <ContentContainer
        background={"bg1"}
        className={clsx(classes.root, classes.rootContainer)}
      >
        <Container disableGutters>
          <Grid container item xs={12} className={classes.main}>
            <Grid item xs={12} md={6} className={classes.leftGroup}>
              <Grid item xs={12} className={classes.motiliLogo}>
                <img
                  alt={"motili-cares-image"}
                  src={motiliCaresImage}
                  width={"340px"}
                  className={classes.topImage}
                />
              </Grid>
              {/*<Grid item xs={12} className={classes.motiliLogo}>*/}
              {/*  <img*/}
              {/*    alt={"mainImage"}*/}
              {/*    src={mainImage}*/}
              {/*    width={"440px"}*/}
              {/*    className={classes.topImage}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <Typography>
                  Motili cares is a community backed organization that supports a wide range of philanthropic activities in the communities we live in.
                  We would welcome your contribution to our great causes!
                  <br />
                  <br />
                </Typography>
                <br />
                {/*<Grid item xs={12} className={classes.motiliLogo}>*/}
                {/*  <img*/}
                {/*    alt={"motili-cares-image"}*/}
                {/*    src={robsim0}*/}
                {/*    width={"280px"}*/}
                {/*    className={classes.topImage}*/}
                {/*  />*/}
                {/*  <img*/}
                {/*    alt={"motili-cares-image"}*/}
                {/*    src={robsim1}*/}
                {/*    width={"280px"}*/}
                {/*    className={classes.topImage}*/}
                {/*  />*/}
                {/*</Grid>*/}
                {/*<Typography>*/}
                {/*  Please select the value below of your donation. We will*/}
                {/*  remember and celebrate the remarkable person that Rob was and*/}
                {/*  provide support to his loving family.*/}
                {/*  <br />*/}
                {/*  <br />*/}
                {/*</Typography>*/}
                {/*<Grid item xs={12} className={classes.motiliLogo}>*/}
                {/*  <img*/}
                {/*      alt={"footerImage"}*/}
                {/*      src={footerImage}*/}
                {/*      width={"340px"}*/}
                {/*      className={classes.topImage}*/}
                {/*  />*/}
                {/*</Grid>*/}
                <br />
                <br />
                <Typography
                  variant={"h5"}
                  className={clsx([classes.donateText])}
                >
                  Please donate today!
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.rightGroup}>
              <Grid item xs={12}>
                <Typography variant={"h2"} className={classes.donateLabel}>
                  Donate Here
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.donateAmountOption}>
                <WizardInputs
                  id={"donateAmount"}
                  options={options}
                  value={donateAmount}
                  className={classes.donateAmount}
                  onChange={(value) => handleDonateAmount(value)}
                />
              </Grid>
              <Grid item xs={12}>
                {donateAmount && Number(donateAmount.value) >= minimumDonate ? (
                  <Grid container item xs={12} className={classes.linkDonate}>
                    <Link
                      to={{
                        pathname: AppRoute.Checkout,
                        state: {
                          type: "credit",
                          amount: donateAmount,
                        },
                      }}
                      type={"credit"}
                    >
                      <Grid item xs={12}>
                        Continue
                      </Grid>
                    </Link>
                  </Grid>
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.linkDonateDisabledMain}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.linkDonateDisabled}
                    >
                      <Grid item xs={12}>
                        Continue
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ContentContainer>
      <Footer />
    </ContentContainer>
  );
}

export default Home;
