import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

import ContentContainer from "components/ContentContainer";
import TextField from "components/FormikTextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import SelectInput from "../../components/common/SelectInput";
import { Formik, FormikValues } from "formik";
import { DonationCart as Cart, PaymentMethod } from "../../models/cart";
import * as yup from "yup";
import { Region } from "../../models/country";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import CartSummary from "components/CartSummary";
import { Heading } from "components/Heading";
import { BrainTree } from "models/financial";
import { Payment, paymentTypes } from "components/Payment";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import Footer from "components/Footer";
import Container from "@material-ui/core/Container";
import WizardHeader from "../../components/WizardHeader";
import Logo from "../../components/LogoTop";
import CheckBox from "../../components/FormikCheckBox";

import creditCardsImage from "../../assets/images/credit-cards.png";
import { Paragraph } from "../../components/Paragraph";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(70),
  },
  rootContainer: {
    borderRadius: "10px 10px 10px 10px",
    maxWidth: "1050px",
    margin: "auto",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(0),
    },
  },
  totalCard: {
    display: "grid",
    gridTemplateRows: "55px 225px 1fr",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: `${theme.spacing(0)}px !important`,
    },
    [theme.breakpoints.up("xs")]: {
      gridTemplateRows: "30px 225px 1fr",
    },
  },
  weAccept: {
    alignSelf: "end",
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(1.5),
    },
  },
  input: {
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1.2),
    borderRadius: "1px",
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
    "&.braintree-hosted-fields-focused": {
      borderColor: theme.palette.text.primary,
    },
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      color: theme.palette.text.primary,
    },
    "&.Mui-checked": {
      color: theme.palette.text.primary,
    },
  },
  logo: {
    width: "40%",
  },
  header: {
    marginBottom: "20px",
    fontWeight: "bold",
  },
  nameContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  nameHeader: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  name: {
    margin: "auto,",
  },
  creditCardsImage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
}));

const postcodeRegex = /^[0-9]{5}$/;
const requiredAddressValidation = yup.object().shape({
  name: yup.string().required("Required").trim(),
  postcode: yup
    .string()
    .length(5, "Only 5 Characters")
    .matches(postcodeRegex, "Invalid Zip Code Characters")
    .required("Required")
    .trim(),
  email: yup.string().email().required("Required").trim(),
});

const notRequiredAddressValidation = yup.object().shape({
  name: yup.string().trim(),
  postcode: yup
    .string()
    .length(5, "Only 5 Characters")
    .matches(postcodeRegex, "Invalid Zip Code Characters")
    .trim(),
  email: yup.string().email("Invalid email").trim(),
});

const validationSchema = yup.object().shape({
  billingAddress: yup.object().when("anonymous", {
    is: true,
    then: notRequiredAddressValidation,
    otherwise: notRequiredAddressValidation, // requiredAddressValidation,
  }),
  anonymous: yup.boolean(),
});

function Purchase(props: Props) {
  const [anonymous, setAnonymous] = useState(false);
  const [validatingEmail, setValidatingEmail] = React.useState(false);
  const [validatingZipcode, setValidatingZipcode] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cart, loading } = props;
  const regions = props?.regions?.map((region) => ({
    id: region.shortCode,
    display: region.name,
  }));
  const billingAddress = {
    ...cart?.billingAddress,
    name: "",
    country: "US",
  };

  let _billReg = regions ? regions[0] : null;
  if (regions && cart.billingAddress && cart.billingAddress.region) {
    _billReg =
      regions.find((reg) => reg.id === cart?.billingAddress?.region) || null;
  }

  const [billingRegion, setBillingRegion] = useState({
    id: "",
    display: "",
  });

  const isFormValid = (isValid: boolean, values: any, billingRegion: any) => {
    return (
      isValid &&
      values.billingAddress &&
      values.billingAddress.city &&
      values.billingAddress.postcode &&
      values.billingAddress.address1 &&
      values.billingAddress.email &&
      values.billingAddress.name &&
      billingRegion?.id
    );
  };

  const getCart = (values: any) => {
    const _billingAddress = {
      ...values.billingAddress,
      anonymous: values.anonymous,
      region: billingRegion?.id,
      contactInformation: {
        ...cart.contactInformation,
        firstname: values.firstname,
        lastname: values.lastname,
      },
    };

    return {
      amount: cart.amount,
      billingAddress: _billingAddress,
      contactInformation: {
        ...cart.contactInformation,
        firstname: values.firstname,
        lastname: values.lastname,
      },
    };
  };
  return (
    <Formik
      initialValues={{
        billingAddress: {
          ...billingAddress,
        },
        anonymous: false,
        firstname: cart?.contactInformation?.firstname,
        lastname: cart?.contactInformation?.lastname,
      }}
      onSubmit={(values) => {
        console.log("submit", values);
      }}
      /*validate={validate}*/
      validationSchema={validationSchema}
      initialTouched={{}}
    >
      {({ isValid, values, setFieldValue }) => {
        return (
          <div>
            <LoadingOverlay open={loading} />
            {/*<WizardHeader showBackButton={true} onBack={() => props.onBack()} />
                        <Divider className={clsx(classes.divider, classes.rootContainer)}/>*/}
            <ContentContainer
              background={"bg1"}
              className={clsx(classes.root, classes.rootContainer)}
            >
              <Container maxWidth={"lg"} disableGutters>
                <ContentContainer disableGrid>
                  <Grid container spacing={10}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.totalCard}
                    >
                      <Logo
                        id={"logo"}
                        className={clsx(classes.logo, classes.header)}
                      />
                      <CartSummary amount={cart.amount} />
                      <Grid item xs={12} className={classes.weAccept}>
                        <Paragraph size={"small"}>We Accept:</Paragraph>
                        <img
                          alt={"credit-cards-image"}
                          src={creditCardsImage}
                          className={classes.creditCardsImage}
                          height={25}
                        />
                        <Paragraph size={"small"}>
                          Your payment details will be processed by Braintree
                          (for credit/debit cards) and a record of your donation
                          will be stored by Motilicares.
                        </Paragraph>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Grid container spacing={2}>
                        {/* billing Address */}
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Heading
                                id={"purchase-heading"}
                                variant={"h2"}
                                label={"Donation Details"}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={classes.nameContainer}
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                className={classes.nameHeader}
                              >
                                <Typography
                                  id={"billing-address-label"}
                                  className={classes.name}
                                >
                                  Name
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6} />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id={"billing-name"}
                                name={"billingAddress.name"}
                                fullWidth
                                autoFocus={true}
                                disabled={anonymous}
                              />
                            </Grid>
                            {/* Email */}
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <TextField
                                    id={"email-address"}
                                    name={"billingAddress.email"}
                                    label={"Email Address"}
                                    subLabel={"Only used for receipt."}
                                    type={"email"}
                                    fullWidth
                                    disabled={anonymous}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <TextField
                                    id={"billing-address"}
                                    name={"billingAddress.address1"}
                                    placeholder={"Street Address"}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id={"billing-address2"}
                                    name={"billingAddress.address2"}
                                    placeholder={"Street Address 2"}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id={"billing-city"}
                                    name={"billingAddress.city"}
                                    placeholder={"City"}
                                    fullWidth
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                  <SelectInput
                                    id={"shipping-region"}
                                    value={billingRegion}
                                    options={regions}
                                    onSelect={(region: any) => {
                                      setBillingRegion(region);
                                    }}
                                    fullWidth
                                    disabled={loading || anonymous}
                                    placeholder={"State"}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <TextField
                                    disabled={loading || anonymous}
                                    id={"shipping-zipcode"}
                                    placeholder={"Zip Code"}
                                    name={"billingAddress.postcode"}
                                    inputProps={{ maxlength: 5 }}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* Payment types */}
                        {props.cart && props.brainTree?.clientToken && (
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Payment
                                  cart={{
                                    ...props.cart,
                                    ...getCart(values),
                                  }}
                                  brainTreeAuthorize={
                                    props.brainTree?.clientToken
                                  }
                                  onPurchase={props.onPurchase}
                                  isFormValid={isFormValid(
                                    isValid,
                                    values,
                                    billingRegion
                                  )}
                                  onCartProcess={props.onCartProcess}
                                  paymentType={props.paymentType}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </ContentContainer>
              </Container>
            </ContentContainer>
            <Footer />
          </div>
        );
      }}
    </Formik>
  );
}

export default Purchase;

interface Props {
  loading: boolean;
  cart: Cart;
  regions?: [Region];
  brainTree?: BrainTree;
  onBack: () => void;
  onExit: () => void;
  onPurchase: (cart: Cart, paymentMethod: PaymentMethod) => void;
  onCartProcess: (loading: boolean, error: any) => void;
  paymentType?: string;
}
