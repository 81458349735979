import React from "react";
import Grid from "@material-ui/core/Grid";
import ContentContainer from "components/ContentContainer";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateCartAmount } from "../store/actions/cartActions";
import { DonationTransaction as Cart } from "../models/cart";
import Footer from "../components/Footer";
import Container from "@material-ui/core/Container";
import CartSummary from "../components/CartSummary";
import { selectDonateState } from "../store/selectors/storeSelector";
import Typography from "@material-ui/core/Typography";
import Logo from "../components/LogoTop";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(70),
    borderRadius: "10px",
  },
  rootContainer: {
    borderRadius: "10px 10px 10px 10px",
    maxWidth: "1050px",
    margin: "auto",
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(0),
    },
  },
  totalCard: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: `${theme.spacing(0)}px !important`,
    },
  },
  input: {
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1.2),
    borderRadius: "1px",
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
    "&.braintree-hosted-fields-focused": {
      borderColor: theme.palette.text.primary,
    },
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      color: theme.palette.text.primary,
    },
    "&.Mui-checked": {
      color: theme.palette.text.primary,
    },
  },
  logo: {
    width: "40%",
  },
  header: {
    marginBottom: "20px",
    fontWeight: "bold",
  },
  purchase: {
    fontWeight: "bold",
  },
}));

function Thankyou() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cart: Cart = useSelector(selectDonateState);

  return (
    <React.Fragment>
      <ContentContainer disableGrid appContainer>
        <ContentContainer
          background={"bg1"}
          className={clsx(classes.root, classes.rootContainer)}
        >
          <Container maxWidth={"lg"} disableGutters>
            <ContentContainer disableGrid>
              <Grid container spacing={10}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.totalCard}
                >
                  <Logo
                    id={"logo"}
                    className={clsx(classes.logo, classes.header)}
                  />
                  <CartSummary amount={cart.data.transactionAmount} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="h3" className={clsx(classes.header)}>
                    Thank You
                  </Typography>
                  <Typography>Your donation has been completed.</Typography>
                  <Typography>&nbsp;</Typography>
                  <Typography className={clsx(classes.purchase)}>
                    {"Donation Number #" +
                      cart.data.transactionReferenceNumber}
                  </Typography>
                  <Typography>&nbsp;</Typography>
                  {!cart.data.anonymous && (
                    <Typography>
                      You will receive a confirmation email to the email you
                      provided. If you have any questions please contact us at
                      the email below.
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </ContentContainer>
          </Container>
        </ContentContainer>
        <Footer />
      </ContentContainer>
    </React.Fragment>
  );
}

export default Thankyou;
