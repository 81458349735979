import { action, payload } from "ts-action";
import { Financial } from "models/financial";
import { Cart, PaymentMethod } from "models/cart";

export const setupCheckout = action("FINANCIAL::SETUP_CHECKOUT");
export const setupCheckoutSuccess = action(
  "FINANCIAL::SETUP_CHECKOUT_SUCCESS",
  payload<Financial>()
);
export const setupCheckoutError = action(
  "FINANCIAL::SETUP_CHECKOUT_ERROR",
  payload<Error>()
);
export const resetFinancial = action("RESET_FINANCIAL::DELETE");

export const setupPaypalButton = action(
  "PAYPAL::SET_BUTTON",
  payload<{
    containerId: string;
    brainTreeToken: string;
    cart: Cart;
    placeOrderFunction: (cart: Cart, paymentMethod: PaymentMethod) => void;
    loadingPaypalButton?: boolean;
    isFormValid?: boolean;
    cartProcessFunction: (loading: boolean, error?: Error) => void;
  }>()
);
export const setupPaypalButtonSuccess = action("PAYPAL::SET_BUTTON_SUCCESS");
export const setupPaypalButtonError = action(
  "PAYPAL::SET_BUTTON_ERROR",
  payload<Error>()
);
