import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(1),
      },
      "&:hover": {
        color: theme.palette.action.active,
        cursor: "pointer",
      },
    },
    active: {
      color: theme.palette.action.active,
    },
  }),
  { name: "common" }
);

interface Props {
  id?: string;
  label?: string;
  href?: string;
  active?: boolean;
  color?: "primary" | "secondary";
  variant?: "subtitle1" | "subtitle2";
  onClick?: () => void;
  children?: any;
}

export function Link(props: Props) {
  const classes = useStyles();
  const {
    label,
    active = false,
    href = "#",
    color = "primary",
    ...other
  } = props;
  return (
    <MuiLink
      underline={"none"}
      variant="subtitle1"
      className={clsx(classes.root, { [classes.active]: active })}
      component={RouterLink}
      to={href}
      color={color === "primary" ? "textPrimary" : "textSecondary"}
      {...other}
    >
      {label}
    </MuiLink>
  );
}

export function FooterLink(props: Props) {
  const classes = useStyles();
  const { label, href = "#", color = "primary", ...other } = props;
  return (
    <MuiLink
      underline={"none"}
      variant="subtitle2"
      className={classes.root}
      component={RouterLink}
      to={href}
      color={color === "primary" ? "textPrimary" : "textSecondary"}
      {...other}
    >
      {label}
    </MuiLink>
  );
}

export function ExternalLink(props: Props) {
  const classes = useStyles();
  const { label, children, href, color = "primary", ...other } = props;
  return (
    <MuiLink
      underline={"none"}
      target={"_blank"}
      variant="subtitle1"
      className={classes.root}
      href={href}
      color={color === "primary" ? "textPrimary" : "textSecondary"}
      {...other}
    >
      {label || children}
    </MuiLink>
  );
}
