import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import ContentContainer from "./ContentContainer";
import { Paragraph } from "./Paragraph";
import ShoppingCartIcon from "components/icons/ShoppingCart";
import Typography from "@material-ui/core/Typography";

interface Props {
  amount?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.divider,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  summaryTitle: {
    fontWeight: "bold",
  },
  shoppingCartIcon: {
    width: "0.9em",
  },
  summaryDetail: {
    fontWeight: "bold",
  },
}));

export default function CartSummary(props: Props) {
  const classes = useStyles();

  const currencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const total = props.amount || 0;

  return (
    <ContentContainer disableGrid className={classes.root}>
      <Grid id={"cart-summary"} container direction={"column"} spacing={2}>
        <Grid item xs={12} container>
          <Typography
            id={"cart-heading"}
            variant={"body1"}
            className={classes.summaryTitle}
          >
            Donation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Paragraph id={"cart-total-label"}>Total</Paragraph>
          </Grid>
          <Grid item xs={6} container justify={"flex-end"} id={"summary-total"}>
            <Typography
              id={"cart-total-label"}
              variant={"body1"}
              className={classes.summaryDetail}
            >
              {currencyFormat.format(total)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}
