import { action, payload } from "ts-action";
import { Country } from "../../models/country";

export const getCountryById = action(
  "COUNTRY::GET_COUNTRY_BY_ID",
  payload<{ id: string }>()
);
export const getCountryByIdSuccess = action(
  "COUNTRY::GET_COUNTRY_BY_ID_SUCCESS",
  payload<Country>()
);
export const getCountryByIdError = action(
  "COUNTRY::GET_COUNTRY_BY_ID_ERROR",
  payload<Error>()
);
