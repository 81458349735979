import { combineEpics } from "redux-observable";
import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import { from, Observable, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";

import { setupCheckOut as setupCheckOutService } from "../services/cartSevices";
import {
  resetFinancial,
  setupCheckout,
  setupCheckoutError,
  setupCheckoutSuccess,
  setupPaypalButton,
  setupPaypalButtonError,
  setupPaypalButtonSuccess,
} from "../actions/financialActions";
import { resetCart } from "../actions/cartActions";
import { RootState } from "../reducers";
import { Dependencies } from "../index";
import { selectCart } from "../selectors/cart";
import { selectFinancial } from "../selectors/financial";
import {
  Client,
  client as braintreeClient,
  dataCollector,
  paypalCheckout,
  PayPalCheckout,
} from "braintree-web";

export const setupCheckout$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { alert }: Dependencies
) =>
  action$.pipe(
    ofType(setupCheckout),
    switchMap(() =>
      setupCheckOutService().pipe(
        map((resp) => setupCheckoutSuccess(resp.data)),
        catchError((error) => {
          alert.error("Unable to setup checkout process");
          return of(setupCheckoutError(error));
        })
      )
    )
  );

export const cleanupDependentState$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>
) =>
  action$.pipe(
    ofType(resetCart),
    withLatestFrom(
      state$.pipe(map(selectCart)),
      state$.pipe(map(selectFinancial))
    ),
    switchMap(([_, cart]) => {
      if (!cart.amount) {
        return [resetFinancial()];
      }
      return [];
    })
  );

export const setupPaypalButton$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { alert }: Dependencies
) =>
  action$.pipe(
    ofType(setupPaypalButton),
    switchMap((action) => {
      const {
        containerId,
        brainTreeToken,
        cart,
        placeOrderFunction,
        isFormValid,
        cartProcessFunction,
      } = action.payload;
      return from(
        braintreeClient.create({
          // @ts-ignore
          authorization: brainTreeToken,
        })
      ).pipe(
        switchMap((clientInstance: Client) => {
          return from(
            paypalCheckout.create({
              client: clientInstance,
            })
          ).pipe(
            // @ts-ignore
            switchMap((paypalCheckoutInstance) =>
              // @ts-ignore
              paypalCheckoutInstance.loadPayPalSDK({
                currency: "USD",
                components: "buttons,messages",
                intent: "authorize",
              })
            ),
            switchMap((paypalCheckoutInstance: PayPalCheckout) => {
              const container = document.querySelector(`#${containerId}`);
              if (container) {
                const paypal = window.paypal;
                // @ts-ignore
                const fundingSources = [paypal.FUNDING.PAYPAL];
                fundingSources.forEach((fundingSource) => {
                  // @ts-ignore
                  const button = paypal.Buttons({
                    style: {
                      layout: "horizontal",
                      color: "blue",
                      label: "pay",
                      height: 40,
                    },
                    enableStandardCardFields: true,
                    fundingSource: fundingSource,
                    createOrder: () => {
                      cartProcessFunction(true);
                      return paypalCheckoutInstance.createPayment({
                        // @ts-ignore
                        flow: "checkout", // Required
                        // @ts-ignore
                        intent: "authorize",
                        amount: cart.prices?.grandTotal, // Required
                        currency: "USD", // Required, must match the currency passed in with loadPayPalSDK

                        enableShippingAddress: true,
                        shippingAddressEditable: false,
                        shippingAddressOverride: {
                          recipientName: `${cart.shippingAddress?.name} ${cart.shippingAddress?.name}`,
                          // @ts-ignore
                          line1: cart.shippingAddress?.address1,
                          line2: cart.shippingAddress?.address2,
                          // @ts-ignore
                          city: cart.shippingAddress?.city,
                          // @ts-ignore
                          countryCode: cart.shippingAddress?.country,
                          // @ts-ignore
                          postalCode: cart.shippingAddress?.postcode,
                          // @ts-ignore
                          state: cart.shippingAddress?.region,
                          phone: cart.shippingAddress?.telephone,
                        },
                      });
                    },
                    // @ts-ignore
                    onApprove: (data) => {
                      // @ts-ignore
                      return paypalCheckoutInstance
                        .tokenizePayment(data)
                        .then((payload) => {
                          dataCollector
                            .create({
                              client: clientInstance,
                              paypal: true,
                            })
                            .then((dataCollectorInstance) => {
                              // Place order with device_data
                              placeOrderFunction(cart, {
                                code: "braintree_paypal",
                                additionalData: {
                                  payment_method_nonce: payload.nonce,
                                  device_data: dataCollectorInstance.deviceData,
                                },
                              });
                            })
                            .catch((error) => {
                              console.error(
                                "dataCollector error then placd order without device_data",
                                error
                              );
                              // Place order without device_data
                              placeOrderFunction(cart, {
                                code: "braintree_paypal",
                                additionalData: {
                                  payment_method_nonce: payload.nonce,
                                },
                              });
                            });
                        })
                        .catch((error) => {
                          cartProcessFunction(false, error);
                          console.error("tokenizePayment Error", error);
                        });
                    },
                    // @ts-ignore
                    onCancel: (data) => {
                      cartProcessFunction(false);
                      console.log("onCancel", data);
                      window.scrollTo(0, 0);
                      alert.error("Paypal payment was Canceled by Customer");
                    },
                    // @ts-ignore
                    onError: (err) => {
                      cartProcessFunction(false);
                      console.log("onError", err);
                      let msg = "Something wrong when try to pay with paypal";
                      switch (err.code) {
                        case "PAYPAL_INVALID_PAYMENT_OPTION":
                          msg = "Invalid shipping address";
                      }
                      window.scrollTo(0, 0);
                      alert.error(msg);
                    },
                    // onInit is called when the button first renders
                    onInit: (data: any, actions: any) => {
                      if (isFormValid) {
                        actions.enable();
                      } else {
                        actions.disable();
                      }
                    },
                  });
                  // Check if the button is eligible
                  if (button.isEligible()) {
                    // Render the standalone button for that funding source
                    button.render(`#${containerId}`);
                  }
                });
              }
              return of(setupPaypalButtonSuccess());
            }),
            catchError((error) => {
              console.error("Unable to setup paypal button");
              return of(setupPaypalButtonError(error));
            })
          );
        })
      );
    }),
    catchError((error) => {
      console.error("Unable to setup paypal button");
      return of(setupPaypalButtonError(error));
    })
  );

export default combineEpics(
  setupCheckout$,
  cleanupDependentState$,
  setupPaypalButton$
);
