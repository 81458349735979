import { applyMiddleware, createStore, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import {
  createTransform,
  persistStore,
  persistReducer,
  PersistConfig,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Action } from "ts-action";
import { History } from "history";
import history from "../router/history";
import { alert, Alert } from "../components/common/AlertProvider";
import { rootReducer, RootState } from "./reducers";
import { rootEpic } from "./epics";
import { appInitial } from "./actions/appActions";

export interface Dependencies {
  history: History;
  alert: Alert;
}
const dependencies = { alert, history };

const ignoreLoadingTransform = createTransform<Object, Object>(
  (inboundState, key) => {
    return {
      ...inboundState,
      loading: false,
    };
  },
  (outboundState, key) => {
    return {
      ...outboundState,
      loading: false,
    };
  },
  {
    whitelist: [
      "country",
      "productSearch",
      "cart",
      "financial",
      "order",
      "content",
    ],
  }
);

const persistConfig: PersistConfig<RootState> = {
  key: "white-site",
  storage,
  blacklist: ["serviceWorker", "store"],
  transforms: [ignoreLoadingTransform],
};

export default function initStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const epicMiddleware = createEpicMiddleware<
    Action,
    Action,
    RootState,
    Dependencies
  >({ dependencies });
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic);
  store.dispatch(appInitial());
  return {
    store,
    persistor,
  };
}
