import http from "./http";
import {
  ContactInformation,
  DonationCart as Cart,
  PaymentMethod,
} from "models/cart";
import { Financial } from "models/financial";
import _ from "lodash";
import config from "config";
import Cookies from "js-cookie";
import { Observable } from "rxjs";

export const setupCheckOut = () =>
  http.post<Financial>("auth/clientToken" /*`/carts/${cartId}/setupCheckout`*/);

export const updateCart = (amount: number, cart: Cart) =>
  http.patch<Cart>(`/carts/${amount}`, cart);

export const placeOrder = (amount: number, cart?: Cart) => {
  const placeOrderCart = {
    billingAddress: cart?.billingAddress,
    contactInformation: cart?.contactInformation,
    amount: cart?.amount,
    paymentMethod: cart?.paymentMethod,
  };

  return http.post("/purchase", {
    data: placeOrderCart,
  });
};

export const updateCartAmount = (cart: Cart) => {
  return new Observable((subscriber) => {
    subscriber.next({
      cart,
    });
  });
};
