import { action, payload } from "ts-action";
import { DonationCart as Cart, PaymentMethod } from "../../models/cart";

export const setLoading = action(
  "CART::SET",
  payload<{ cart: Cart; loading: boolean }>()
);

export const placeOrder = action(
  "CART::PLACE_ORDER",
  payload<{ cart: Cart; paymentMethod?: PaymentMethod }>()
);
export const placeOrderSuccess = action(
  "CART::PLACE_ORDER_SUCCESS",
  payload<any>()
);
export const placeOrderError = action(
  "CART::PLACE_ORDER_ERROR",
  payload<Error>()
);

export const updateCartAmount = action(
  "CART::UPDATE_CART_AMOUNT",
  payload<{ cart: Cart }>()
);
export const updateCartSuccess = action(
  "CART::UPDATE_CART_SUCCESS",
  payload<any>()
);
export const updateCartError = action(
  "CART::UPDATE_CART_ERROR",
  payload<Error>()
);
export const resetCart = action("CART::RESET");
export const cartProcess = action(
  "CART::PROCESS",
  payload<{ loading: boolean; error?: any }>()
);
