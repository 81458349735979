import { on, reducer } from "ts-action";

import {
  resetFinancial,
  setupPaypalButton,
  setupPaypalButtonError,
  setupPaypalButtonSuccess,
  setupCheckout,
  setupCheckoutSuccess,
  setupCheckoutError,
} from "../actions/financialActions";
import { Financial } from "../../models/financial";

interface FinancialState extends Financial {
  loading: boolean;
  error?: Error;
  cartId?: string;
  loadingPaypalButton?: boolean;
}

const initialState: FinancialState = {
  loading: false,
  loadingPaypalButton: false,
};

export const financialReducer = reducer(
  initialState,
  on(setupCheckout, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(setupCheckoutSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
  })),
  on(setupCheckoutError, (state, action) => ({
    ...state,
    brainTree: {},
    affirm: {},
    error: action.payload,
    loading: false,
  })),
  on(resetFinancial, (state, action) => ({
    ...initialState,
  })),
  on(setupPaypalButton, (state, action) => ({
    ...state,
    loadingPaypalButton: action.payload.loadingPaypalButton,
  })),
  on(setupPaypalButtonSuccess, (state, action) => ({
    ...state,
    loadingPaypalButton: false,
  })),
  on(setupPaypalButtonError, (state, action) => ({
    ...state,
    loadingPaypalButton: false,
  }))
);

export default financialReducer;
