import { combineReducers } from "redux";
import serviceWorker from "./serviceWorkerReducer";
import cart from "./cartReducer";
import financial from "./financialReducer";
import country from "./countryReducer";
import donate from "./donateReducer";

export const rootReducer = combineReducers({
  serviceWorker,
  country,
  cart,
  financial,
  donate,
});
export type RootState = ReturnType<typeof rootReducer>;
