import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    italic: {
      fontStyle: "italic",
    },
  }),
  { name: "common" }
);

interface Props {
  id?: string;
  color?: "primary" | "secondary";
  size?: "normal" | "small";
  italic?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  customClasses?: string;
  "data-page-type"?: string;
  "data-amount"?: number;
}

export function Paragraph(props: PropsWithChildren<Props>) {
  const classes = useStyles();
  const {
    italic = false,
    size = "normal",
    color = "primary",
    children,
    customClasses,
    ...other
  } = props;
  return (
    <Typography
      variant={size === "normal" ? "body1" : "body2"}
      color={color === "primary" ? "textPrimary" : "textSecondary"}
      className={clsx(
        classes.root,
        { [classes.italic]: italic },
        customClasses
      )}
      {...other}
    >
      {children}
    </Typography>
  );
}
