import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    label: {
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
      "&.Mui-error": {
        color: theme.palette.error.main,
      },
    },
    required: {
      color: "#F14F27",
    },
    subLabel: {
      color: "#2B2B2B",
      fontSize: theme.spacing(1.1),
      marginLeft: theme.spacing(2),
    },
    input: {
      borderRadius: "1px",
      height: theme.spacing(4),
      "& input": {
        padding: theme.spacing(1.2),
      },
      "&.MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: theme.palette.text.primary,
        },
        "& fieldset": {
          border: `1px solid ${theme.palette.divider}`,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.text.primary,
          borderWidth: "1px",
        },
      },
    },
    inputError: {
      color: "#F14F27",
    },
    helperText: {
      color: theme.palette.text.primary,
    },
  }),
  { name: "common" }
);

interface Props {
  id: string;
  type?: string;
  label?: string;
  subLabel?: string;
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  placeholder?: string;
  enableErrorDisplay?: boolean;
  value?: string;
  required?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}
export function TextField(props: Props) {
  const classes = useStyles();
  const {
    id,
    type = "text",
    label,
    subLabel,
    fullWidth,
    helperText,
    error,
    placeholder,
    enableErrorDisplay = false,
    required,
    ...other
  } = props;
  return (
    <FormControl
      id={`${id}-root`}
      fullWidth={fullWidth}
      className={classes.root}
      error={enableErrorDisplay && error}
    >
      {label && (
        <FormLabel id={`${id}-label`} htmlFor={id} className={classes.label}>
          {label}
          {required && <span className={classes.required}>&nbsp;*</span>}
          {subLabel && <span className={classes.subLabel}>{subLabel}</span>}
        </FormLabel>
      )}
      <OutlinedInput
        id={id}
        fullWidth={fullWidth}
        type={type}
        className={clsx({
          [classes.input]: true,
          [classes.inputError]: error,
        })}
        placeholder={placeholder}
        {...other}
      />
      {enableErrorDisplay && (
        <FormHelperText id={`${id}-helper`} className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
