import { Observable } from "rxjs";
import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import { swUpdate, swUpdateSuccess } from "../actions/serviceWorkerActions";
import { map } from "rxjs/operators";

export const autoUpdateServiceWorker$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(swUpdate),
    map((action) => {
      const registrationWaiting = action.payload?.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e) => {
          if ((e.target as ServiceWorker)?.state === "activated") {
            window.location.reload();
          }
        });
      }
      return swUpdateSuccess();
    })
  );
