import React from "react";
import { useField } from "formik";
import { TextField } from "./TextField";

interface Props {
  id?: string;
  fullWidth?: boolean;
  name: string;
  label?: string;
  subLabel?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  inputProps?: object;
  required?: boolean;
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export default function FormikTextField(props: Props) {
  const {
    id,
    placeholder,
    fullWidth = true,
    name,
    label,
    subLabel,
    ...other
  } = props;
  const [field, meta] = useField(props);
  return (
    <TextField
      id={id ? id : `${name}-input`}
      label={label}
      subLabel={subLabel}
      fullWidth={fullWidth}
      placeholder={placeholder}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : ""}
      {...other}
      {...field}
    />
  );
}
