import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { Braintree, HostedField } from "react-braintree-fields";
import { Button } from "./Button";
import { DonationCart as Cart, PaymentMethod } from "models/cart";
import { setupPaypalButton } from "store/actions/financialActions";
import { setLoading } from "store/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "components/TextField";
import {
  selectLoadingPaypalButton,
  selectAffirm,
} from "store/selectors/financial";
import LoadingOverlay from "components/common/LoadingOverlay";
import clsx from "clsx";
import { PaymentOptionRadio } from "components/checkout/PaymentOptionRadio";
import CreditCard from "./icons/CreditCard";
import Affirm from "./icons/Affirm";
import Paypal from "./icons/Paypal";
import Typography from "@material-ui/core/Typography";
import { useDebouncedCallback } from "use-debounce";
import { alert } from "components/common/AlertProvider";
import { Paragraph } from "components/Paragraph";
import http from "../store/services/http";

interface Props {
  cart: Cart;
  brainTreeAuthorize?: string;
  affirmConfig?: any;
  onPurchase: (cart: Cart, paymentMethod: PaymentMethod) => void;
  isFormValid?: boolean;
  onCartProcess: (loading: boolean, error: any) => void;
  paymentType?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 0,
  },
  creditCardInput: {
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0, 1.2),
    borderRadius: "1px",
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
    "&.braintree-hosted-fields-focused": {
      borderColor: theme.palette.text.primary,
    },
  },
  label: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    "&.Mui-focused": {
      color: theme.palette.text.primary,
    },
    "&.Mui-error": {
      color: theme.palette.error.main,
    },
  },
  paypalButtonContainer: {
    width: "100%",
  },
  creditCardButton: {
    width: "100%",
  },
  disableButton: {
    "pointer-events": "none",
    opacity: 0.4,
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
  },
  buttonContainer: {
    minHeight: "70px",
  },
  title: {
    fontWeight: "bold",
  },
  required: {
    color: "#F14F27",
  },
}));
export const paymentTypes = {
  PAYPAL: "PAYPAL",
  CREDIT_CARD: "CREDIT_CARD",
  AFFIRM: "AFFIRM",
};

const DonateState = {
  NONE: 0,
  VERIFYING: 1,
  DONATING: 2,
};

export function Payment(props: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = React.useState(
    props.paymentType ? props.paymentType : paymentTypes.CREDIT_CARD
  );
  const [affirmProcessing, setAffirmProcessing] = React.useState(false);
  const paypalButtonsContainerId = "paypal-button-container";
  const paypalButtonLoading = useSelector(selectLoadingPaypalButton);
  const affirmConfig = useSelector(selectAffirm);
  const handleChangePayment = (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value === paymentTypes.PAYPAL) {
      dispatch(
        setupPaypalButton({
          containerId: paypalButtonsContainerId,
          // @ts-ignore
          brainTreeToken: props.brainTreeAuthorize,
          placeOrderFunction: props.onPurchase,
          cart: props.cart,
          loadingPaypalButton: props.isFormValid,
          isFormValid: props.isFormValid,
          cartProcessFunction: props.onCartProcess,
        })
      );
    } else if (
      (event.target as HTMLInputElement).value === paymentTypes.CREDIT_CARD
    ) {
      setCreditCardValid(false);
    }

    setPaymentType((event.target as HTMLInputElement).value);
  };
  const [creditCardReady, setCreditCardReady] = useState<boolean>(false);
  const [tokenizer, setTokenizeFunc] = useState<any>(null);
  const [deviceData, setDeviceData] = useState<any>(null);
  const [creditCardValid, setCreditCardValid] = useState<boolean>(false);
  const [creditCardFirstName, setCreditCardFirstName] = useState<
    string | undefined
  >();
  const [creditCardLastName, setCreditCardLastName] = useState<
    string | undefined
  >();
  const [donateState, setDonateState] = useState({
    state: DonateState.NONE,
    customerId: null,
  });

  const donate = async () => {
    const token = await tokenizer.ref({
      cardholderName: `${creditCardFirstName} ${creditCardLastName}`,
    });
    if (token?.nonce) {
      props.onPurchase(props.cart, {
        code: "braintree",
        customerId: donateState.customerId || "",
        additionalData: {
          payment_method_nonce: token.nonce,
          device_data: deviceData,
          creditCardFirstName,
          creditCardLastName,
        },
      });
    }
  };

  const verifyCard = async () => {
    const token = await tokenizer.ref({
      cardholderName: `${creditCardFirstName} ${creditCardLastName}`,
    });

    if (token?.nonce) {
      dispatch(
        setLoading({
          cart: props.cart,
          loading: true,
        })
      );

      verify({
        creditCardLastName,
        creditCardFirstName,
        email: props.cart.billingAddress ? props.cart.billingAddress.email : "",
        streetAddress: props.cart.billingAddress
          ? props.cart.billingAddress.address1 +
            " " +
            props.cart.billingAddress.address2
          : "",
        postalCode: props.cart.billingAddress
          ? props.cart.billingAddress.postcode
          : "",
        payment: {
          payment_method_nonce: token.nonce,
        },
      })
        .then(async (resp) => {
          setDonateState({
            state: DonateState.DONATING,
            customerId: resp.data.customer.id,
          });
        })
        .catch((error) => {
          alert.error(
            "Payment verification failed. Please enter valid card information."
          );
          dispatch(
            setLoading({
              cart: props.cart,
              loading: false,
            })
          );
          return;
        });
    }
  };

  useEffect(() => {
    switch (donateState.state) {
      case DonateState.VERIFYING: {
        verifyCard();
        break;
      }
      case DonateState.DONATING: {
        donate();
        break;
      }
    }
  }, [donateState]);

  const verify = async (data: {
    creditCardLastName: string | undefined;
    creditCardFirstName: string | undefined;
    email: string | undefined;
    streetAddress: string | undefined;
    postalCode: string | undefined;
    payment: {
      payment_method_nonce: any;
    };
  }) => {
    return http
      .post("/purchase/verify", {
        data: data,
      })
      .toPromise();
  };

  const payByCreditCard = async () => {
    setDonateState({ state: DonateState.VERIFYING, customerId: null });
  };

  const debouncedSetupPaypalButton = useDebouncedCallback(
    // function
    () => {
      dispatch(
        setupPaypalButton({
          containerId: paypalButtonsContainerId,
          // @ts-ignore
          brainTreeToken: props.brainTreeAuthorize,
          placeOrderFunction: props.onPurchase,
          cart: props.cart,
          loadingPaypalButton: false,
          isFormValid: props.isFormValid,
          cartProcessFunction: props.onCartProcess,
        })
      );
    },
    // delay in ms
    1000
  );

  const { name, city, country, postcode, region } =
    props.cart.billingAddress || {};
  useEffect(() => {
    if (props.isFormValid && paymentType === paymentTypes.PAYPAL) {
      debouncedSetupPaypalButton.callback();
    }
  }, [
    props.isFormValid,
    name,
    city,
    country,
    postcode,
    region,
    paymentType,
    debouncedSetupPaypalButton,
  ]);
  return (
    <Grid container direction={"column"}>
      <LoadingOverlay
        open={
          (paymentType === paymentTypes.PAYPAL && paypalButtonLoading) ||
          affirmProcessing
        }
      />

      <FormControl component="fieldset">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/*<RadioGroup
                            id={'payment-type'}
                            aria-label="paymentType"
                            name="paymentType"
                            value={paymentType}
                            onChange={handleChangePayment}
                        >
                        </RadioGroup>*/}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/*{props.brainTreeAuthorize && (
                                        <Grid item xs={12}>
                                            <PaymentOptionRadio
                                                id={'payment-type-credit-card'}
                                                label={'Use Credit Card'}
                                                value={paymentTypes.CREDIT_CARD}
                                                icon={<CreditCard />}
                                                selected={paymentType === paymentTypes.CREDIT_CARD}
                                            />
                                        </Grid>
                                    )}*/}
                {paymentType === paymentTypes.CREDIT_CARD && (
                  <>
                    <Grid item xs={12} container>
                      <Grid item xs={12}>
                        <Typography
                          id={"shipping-address-label"}
                          className={classes.title}
                        >
                          Credit/Debit Card Details
                        </Typography>
                      </Grid>
                      <Braintree
                        classname={creditCardReady ? "" : "disabled"}
                        authorization={props.brainTreeAuthorize}
                        onAuthorizationSuccess={() => {
                          setCreditCardReady(true);
                        }}
                        onDataCollectorInstanceReady={(
                          err: any,
                          dataCollectorInstance: any
                        ) => {
                          if (!err) {
                            setDeviceData(dataCollectorInstance.deviceData);
                          }
                        }}
                        getTokenRef={(ref: any) => {
                          setTokenizeFunc({ ref });
                        }}
                        onValidityChange={(cardValidation: any) => {
                          setCreditCardValid(
                            cardValidation?.fields?.cvv?.isValid &&
                              cardValidation?.fields?.expirationDate?.isValid &&
                              cardValidation?.fields?.number?.isValid
                          );
                        }}
                        styles={{
                          input: {
                            "font-size": theme.typography.body1.fontSize,
                            "font-family": `${theme.typography.body1.fontFamily}, Roboto, Helvetica, Arial, Sans-serif`,
                            "font-weight": theme.typography.body1.fontWeight,
                            color: theme.palette.text.primary,
                          },
                          ":focus": {
                            color: theme.palette.text.primary,
                          },
                          ".valid": {
                            color: theme.palette.success.main,
                          },
                          ".invalid": {
                            color: theme.palette.error.main,
                          },
                          "::placeholder": {
                            opacity: 0.5,
                            "font-weight": "100 !important",
                          },
                        }}
                      >
                        <Grid
                          container
                          spacing={1}
                          className={classes.paddingTop}
                        >
                          <Grid item xs={12}>
                            <Typography id={"shipping-address-label"}>
                              Name on Card
                              {/* <span className={classes.required}>*</span>*/}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id={"first-name-on-card"}
                              placeholder={"First Name"}
                              fullWidth
                              value={creditCardFirstName}
                              onChange={(event) => {
                                setCreditCardFirstName(event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id={"last-name-on-card"}
                              placeholder={"Last Name"}
                              fullWidth
                              value={creditCardLastName}
                              onChange={(event) => {
                                setCreditCardLastName(event.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography id={"shipping-address-label"}>
                              Credit Card Information{" "}
                              {/*<span className={classes.required}>*</span>*/}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <HostedField
                              id={"card-number-text-field"}
                              type={"number"}
                              placeholder={"Card Number: 0000 0000 0000 0000"}
                              className={classes.creditCardInput}
                              onEmpty={() => {
                                setCreditCardValid(false);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <HostedField
                              id={"card-exp-date-text-field"}
                              type={"expirationDate"}
                              placeholder={"Exp Date: 00/00"}
                              className={classes.creditCardInput}
                              onEmpty={() => {
                                setCreditCardValid(false);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <HostedField
                              id={"ccv-text-field"}
                              type="cvv"
                              placeholder={"CVV"}
                              className={classes.creditCardInput}
                              onEmpty={() => {
                                setCreditCardValid(false);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Braintree>
                    </Grid>
                  </>
                )}
              </Grid>
              {/*<Grid item xs={12}>
                                    {props.brainTreeAuthorize && (
                                        <Grid item xs={12}>
                                            <PaymentOptionRadio
                                                id={'payment-type-paypal'}
                                                label={'Use Paypal'}
                                                value={paymentTypes.PAYPAL}
                                                icon={<Paypal />}
                                                selected={paymentType === paymentTypes.PAYPAL}
                                            />
                                        </Grid>
                                    )}
                                </Grid>*/}
              {/*<Grid item xs={12}>
                                    {affirmConfig && (
                                        <Grid item xs={12}>
                                            <PaymentOptionRadio
                                                id={'payment-type-affirm'}
                                                label={'Pay Using Financing'}
                                                value={paymentTypes.AFFIRM}
                                                icon={<Affirm />}
                                                selected={paymentType === paymentTypes.AFFIRM}
                                            />
                                        </Grid>
                                    )}
                                </Grid>*/}
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.buttonContainer}>
            {paymentType === paymentTypes.CREDIT_CARD && (
              <div
                className={classes.creditCardButton}
                hidden={paymentType !== paymentTypes.CREDIT_CARD}
              >
                <Button
                  style={{
                    width: "100%",
                    color: theme.palette.primary.light,
                  }}
                  disabled={
                    !props.isFormValid ||
                    !creditCardValid ||
                    !creditCardFirstName ||
                    creditCardFirstName?.trim() === "" ||
                    !creditCardLastName ||
                    creditCardLastName?.trim() === ""
                  }
                  id={"credit-card-button"}
                  color={"primary"}
                  onClick={payByCreditCard}
                >
                  DONATE
                </Button>
              </div>
            )}
            {paymentType === paymentTypes.PAYPAL && (
              <div
                className={clsx(
                  classes.paypalButtonContainer,
                  props.isFormValid ? undefined : classes.disableButton
                )}
                hidden={paymentType !== paymentTypes.PAYPAL}
                id={paypalButtonsContainerId}
              />
            )}

            {/*affirmConfig && paymentType === paymentTypes.AFFIRM && (
                            <div className={classes.creditCardButton} hidden={paymentType !== paymentTypes.AFFIRM}>
                                {props?.cart?.prices?.grandTotal && (
                                    <Paragraph
                                        customClasses={'affirm-as-low-as'}
                                        data-page-type={'product'}
                                        data-amount={toCent(props?.cart?.prices?.grandTotal)}
                                    />
                                )}

                                <Button
                                    style={{
                                        width: '100%',
                                    }}
                                    disabled={!props.isFormValid}
                                    id={'affirm-button'}
                                    color={'primary'}
                                    onClick={payByAffirm}
                                >
                                    PURCHASE
                                </Button>
                            </div>
                        )*/}
          </Grid>
        </Grid>
      </FormControl>
    </Grid>
  );
}
