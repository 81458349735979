import { on, reducer } from "ts-action";

import {
  getCountryById,
  getCountryByIdError,
  getCountryByIdSuccess,
} from "../actions/countryActions";
import { Country } from "../../models/country";

const initialState: Country = {};
export const orderReducer = reducer(
  initialState,
  on(getCountryById, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(getCountryByIdSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
  })),
  on(getCountryByIdError, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
);

export default orderReducer;
