import { combineEpics } from "redux-observable";
import { Action } from "ts-action";
import { ofType } from "ts-action-operators";
import { Observable, of } from "rxjs";
import {
  switchMap,
  map,
  catchError,
  withLatestFrom,
  tap,
  ignoreElements,
} from "rxjs/operators";

import {
  placeOrder as placeOrderService,
  updateCartAmount as updateCartAmountService,
} from "../services/cartSevices";

import {
  setLoading,
  placeOrder,
  placeOrderSuccess,
  placeOrderError,
  updateCartAmount,
  resetCart,
} from "../actions/cartActions";
import { setDonationCart } from "../actions/donateActions";
import { resetFinancial } from "store/actions/financialActions";
import { RootState } from "../reducers";
import { selectCart } from "../selectors/cart";
import { Dependencies } from "../index";
import { AppRoute } from "../../models/route";

export const setLoading$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { alert }: Dependencies
) =>
  action$.pipe(
    ofType(setLoading),
    withLatestFrom(state$.pipe(map(selectCart))),
    switchMap(([action]) => {
      return [];
    })
  );

export const placeOrder$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { alert }: Dependencies
) =>
  action$.pipe(
    ofType(placeOrder),
    switchMap((action) =>
      placeOrderService(action.payload.cart.amount!, {
        ...action.payload.cart,
        paymentMethod: action.payload.paymentMethod,
      }).pipe(
        switchMap((resp) => {
          const successData = {
            data: resp.data
              ? resp.data
                ? { ...resp.data.paymentData, anonymous: resp.data.anonymous }
                : {}
              : {},
            nextRoute: AppRoute.Thankyou,
          };
          console.log("placeOrder successData");
          console.log(successData);
          return [
            resetCart(),
            resetFinancial(),
            setDonationCart(successData),
            placeOrderSuccess(successData),
          ];
        }),
        catchError((error) => {
          alert.error(
            "Payment verification failed. Please enter valid card information."
          );
          return of(placeOrderError(error));
        })
      )
    )
  );

export const updateCartAmount$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { alert }: Dependencies
) =>
  action$.pipe(
    ofType(updateCartAmount),
    withLatestFrom(state$.pipe(map(selectCart))),
    switchMap(([action]) => {
      return updateCartAmountService(action.payload.cart).pipe(
        switchMap(() => {
          return [];
        }),
        catchError((error) => {
          alert.error("Unable to place an order");
          return of(placeOrderError(error));
        })
      );
    })
  );

export const navigateAfterOrderSuccess$ = (
  action$: Observable<Action>,
  state$: Observable<RootState>,
  { history }: Dependencies
) =>
  action$.pipe(
    ofType(placeOrderSuccess),
    withLatestFrom(state$.pipe(map(selectCart))),
    tap(([action]) => {
      console.log("placeOrder success");
      if (action.payload.nextRoute) {
        history.push(action.payload.nextRoute);
      }
    }),
    ignoreElements()
  );

export default combineEpics(
  setLoading$,
  placeOrder$,
  navigateAfterOrderSuccess$,
  updateCartAmount$
);
