import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const selectFinancial = (state: RootState) => state.financial;
export const selectBrainTree = createSelector(
  selectFinancial,
  (financial) => financial.brainTree
);
export const selectLoading = createSelector(
  selectFinancial,
  (financial) => financial.loading || false
);
export const selectLoadingPaypalButton = createSelector(
  selectFinancial,
  (financial) => financial.loadingPaypalButton || false
);
export const selectAffirm = createSelector(
  selectFinancial,
  (financial) => financial.affirm
);
