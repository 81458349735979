import * as React from "react";
import motiliBand from "../assets/images/motili_cares_logo.png";

interface Props {
  id: string;
  alt?: string;
  className?: string;
}

export default function LogoTop(props: Props) {
  return (
    <img
      src={motiliBand}
      id={props.id}
      alt={props.alt}
      className={props.className}
    />
  );
}
