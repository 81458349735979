import { reducer, on } from "ts-action";
import { swInit, swUpdate } from "../actions/serviceWorkerActions";

export interface ServiceWorkerState {
  serviceWorkerInitialized: boolean;
  serviceWorkerUpdated: boolean;
  serviceWorkerRegistration: null | ServiceWorkerRegistration;
}

const initialState: ServiceWorkerState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

export const serviceWorkerReducer = reducer(
  initialState,
  on(swInit, (state, action) => ({
    ...state,
    serviceWorkerInitialized: true,
    serviceWorkerRegistration: action.payload,
  })),
  on(swUpdate, (state, action) => ({
    ...state,
    serviceWorkerUpdated: true,
    serviceWorkerRegistration: action.payload,
  }))
);

export default serviceWorkerReducer;
