import { on, reducer } from "ts-action";
import { DonationTransaction as Cart } from "../../models/cart";

import { setDonationCart } from "../actions/donateActions";

const initialState: Cart = {};
export const donateReducer = reducer(
  initialState,
  on(setDonationCart, (state, action) => ({
    ...state,
    ...action.payload,
  }))
);

export default donateReducer;
