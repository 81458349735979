import { AppConfig } from "./models/appConfig";

import theme1 from "./themes/theme1";
const theme = getTheme();

const config: AppConfig = {
  appName: process.env.REACT_APP_NAME || "Motii Cares",
  appVersion: process.env.REACT_APP_VERSION || "0.0.1",
  apiHost: process.env.REACT_APP_API_HOST || "http://localhost:3091/api/mdpa",
  httpTimeout: 60000,
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "",
  minimumDonateValue: Number(process.env.REACT_APP_MINIMUM_DONATE_VALUE) || 1,
  ...theme,
};

function getTheme() {
  return { theme: theme1 };
}

export default config;
