import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { AppRoute } from "models/route";
import { DonationCart as Cart, PaymentMethod } from "models/cart";
import { selectCart, selectCartProcessing } from "store/selectors/cart";
import {
  selectLoading as selectFinancialLoading,
  selectBrainTree,
} from "store/selectors/financial";
import { placeOrder, cartProcess } from "store/actions/cartActions";
import { selectRegions } from "store/selectors/country";
import Purchase from "pages/checkout/Purchase";
import { setupCheckout } from "../../store/actions/financialActions";
import Loading from "../../components/common/Loading";
import { paymentTypes } from "components/Payment";
import ContentContainer from "../../components/ContentContainer";

function PurchaseContainer() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart: Cart = useSelector(selectCart);
  const loading = useSelector(selectCartProcessing);
  const financialLoading = useSelector(selectFinancialLoading);
  const regions = useSelector(selectRegions);
  const brainTree = useSelector(selectBrainTree);
  const [paymentType] = useState(paymentTypes.CREDIT_CARD);

  useEffect(() => {
    dispatch(setupCheckout());
  }, [dispatch]);

  if (!cart.amount) {
    return <Redirect to={AppRoute.Home} />;
  }

  const placeOrderFunction = (cart: Cart, paymentMethod: PaymentMethod) => {
    if (!!cart && cart.amount && !!paymentMethod) {
      dispatch(
        placeOrder({
          cart: cart,
          paymentMethod: paymentMethod,
        })
      );
    }
  };
  const cartProcessFunction = (loading: boolean, error: any) => {
    dispatch(cartProcess({ loading, error }));
  };

  return (
    <ContentContainer disableGrid appContainer>
      <Purchase
        loading={loading || financialLoading}
        cart={cart}
        regions={regions}
        brainTree={brainTree}
        onBack={() => {
          history.push(AppRoute.Home);
        }}
        onExit={() => {}}
        onPurchase={placeOrderFunction}
        onCartProcess={cartProcessFunction}
        paymentType={paymentType}
      />
    </ContentContainer>
  );
}

export default PurchaseContainer;
