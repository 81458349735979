import React from "react";
import "./App.css";
import Router from "./router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./themes/theme";
import { AlertProvider } from "./components/common/AlertProvider";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AlertProvider>
        <Router />
      </AlertProvider>
    </ThemeProvider>
  );
}

export default App;
