import { createSelector } from "reselect";
import { RootState } from "../reducers";

export const selectFinancialState = (state: RootState) => state.financial;
export const selectFinancialLoading = createSelector(
  selectFinancialState,
  (state) => state.loading
);

export const selectCountryState = (state: RootState) => state.country;
export const selectCountryLoading = createSelector(
  selectCountryState,
  (state) => state.loading
);

export const selectDonateState = (state: RootState) => state.donate;
